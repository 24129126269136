import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import '../../../App.css';
import  Apple  from '../../../assets/images/App Store.png';
import  Google  from '../../../assets/images/Play Store.png';

import AppAbout from "./about";
import AppComponent from "./AppComponent";

import AppContact from "./contact";
import AppFaq from "./faq";
import AppHero from "./hero";
import Partners from "./Partners";
import Service from "./Service";
import AppWorks from "./works";

const AppComponent2 = ({ data }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {}, []);

  return (
    <>
      <div
        className="main"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <div className='titleHolder mgn-3rem'>
            <h2>
            {t('Download Now')}                
            </h2>
          </div>

        <div className='Grid2-Columns'>
          <div></div>
        <div className='Grid2Center'>
            <a href="https://play.google.com/store/apps/details?id=com.almedad.unipay" >
            <img className='App-subContent-2Coulm-img2' src={Google} alt="Play Store" />
            </a>
        </div>
        <div className='Grid2Center'>
            <a href="https://apps.apple.com/app/id1671543376">
            <img className='App-subContent-2Coulm-img2' src={Apple} alt="App Store" />
            </a>
          </div>
          <div></div>
        </div>
        {/* <AppHero data={data.Headers} t={t} i18n={i18n} />
        <AppAbout
          section={
            data.Sections
              ? data?.Sections?.filter((e) => e.SectionName === "AboutUs")[0]
                  ?.Text[0]
              : null
          }
          data={data.Benefits}
          t={t}
          i18n={i18n}
        />
        <AppContact data={data.Branches} t={t} i18n={i18n} /> */}
      </div>
    </>
  );
};

export default AppComponent2;
