import React, { useCallback, useState } from "react";
import { Card, Col, Row } from "antd";
import { Form, Input, Button, Checkbox } from "antd";
import notify from "devextreme/ui/notify";
import { CONTACT_US } from "../../../services/SharedApi/Section.Api";
const { TextArea } = Input;

function AppContact({ t, i18n, data = [] }) {
  const Datax = [
    {
      Country: "Egypt",
      City: "cairo",
      Address: "110, Merghani, 110 Al Merghani, St, Heliopolis, Cairo ",
      Numbers: [
        {
          PhoneNumber: "01095986507",
        },
        { PhoneNumber: "01095986507" },
        { PhoneNumber: "01095986507" },
      ],
    },
    {
      Country: "Egypt",
      City: "cairo",
      Address: "110, Merghani, 110 Al Merghani, St, Heliopolis, Cairo ",
      Numbers: [
        {
          PhoneNumber: "01095986507",
        },
        { PhoneNumber: "01095986507" },
        { PhoneNumber: "01095986507" },
      ],
    },
    {
      Country: "Egypt",
      City: "cairo",
      Address: "110, Merghani, 110 Al Merghani, St, Heliopolis, Cairo ",
      Numbers: [
        {
          PhoneNumber: "01095986507",
        },
        { PhoneNumber: "01095986507" },
        { PhoneNumber: "01095986507" },
      ],
    },
  ];
  const [selectedperson, setselectedperson] = useState(-1);
  const [Values, setValues] = useState({
    Name: "",
    Email: "",
    PhoneNumber: "",
    Reason: "",
    Message: "",
  });

  const Person_details = useCallback(
    (e) => {
      if (Number(selectedperson) === Number(e)) {
        setselectedperson(-1);
      } else {
        setselectedperson(e);
      }
    },
    [selectedperson, data]
  );
  const OnSubmit = async (id) => {
    await CONTACT_US(Values)
      .then((res) => {
        notify(
          "Message Send Successfully we will connect with you as soon as possible",
          "success",
          3000
        );
      })
      .catch(() => {
        notify("Error in information. try again! ", "error", 3000);
      });
  };
  const formRef = React.createRef();
  const onFinish = async (valuesx) => {
    await CONTACT_US(Values)
      .then((res) => {
        notify(
          t(
            "Message Send Successfully we will connect with you as soon as possible"
          ),
          "success",
          3000
        );
        formRef.current?.resetFields();
      })
      .catch(() => {
        notify(t("Error in information. try again! "), "error", 3000);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notify(t("Please Fill the inputs"), "error", 3000);
  };

  return (
    <div id="/home#contact" className="block contactBlock">
      <div className="titleHolder">
        <h2>{t("Contact Us")}</h2>
      </div>
      <Row justify="center" gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
          <div className="container-fluid">
            <Form
              ref={formRef}
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "Please enter your full name!",
                  },
                ]}
              >
                <Input
                  placeholder={t("Full Name")}
                  value={Values.Name}
                  onVolumeChange={(e) => console.log(e)}
                  name={"Name"}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  placeholder={t("Email")}
                  value={Values.Email}
                  name={"Email"}
                />
              </Form.Item>
              <Form.Item name="phone">
                <Input
                  placeholder={t("Phone Number")}
                  value={Values.PhoneNumber}
                  name={"PhoneNumber"}
                />
              </Form.Item>
              <Form.Item name="subject">
                <Input
                  placeholder={t("Subject")}
                  value={Values.Reason}
                  name={"Reason"}
                />
              </Form.Item>
              <Form.Item name="message">
                <TextArea
                  placeholder={t("Message")}
                  value={Values.Message}
                  name={"Message"}
                />
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  noStyle
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject("Should accept agreement"),
                    },
                  ]}
                >
                  <Checkbox>{t("I agree with terms and conditions.")}</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={OnSubmit}
                >
                  {t("Send")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <div class="vertical-line">
          <span>{t("Or")}</span>
        </div>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
          <div style={{ margin: "20px", textAlign: "center" }}>
            {data?.map((item, index) => (
              <div
                style={{
                  direction: i18n.language === "en" ? "ltr" : "rtl",
                  borderBottom: "1px solid rgb(155 155 155)",
                  padding: "13px",
                }}
              >
                <h2 style={{ cursor: "pointer" }}>
                  {i18n.language === "en"
                    ? item.CountryEn + " , " + item.CityEn
                    : item.Country + " , " + item.City}
                </h2>
                <h5 style={{ cursor: "pointer", color: "rgb(155 155 155)" }}>
                  {" "}
                  {i18n.language === "en" ? item.AddressEn : item.Address}
                </h5>
                {item.Numbers.length > 0 ? (
                  <div
                    onClick={() => Person_details(index)}
                    style={{ cursor: "pointer", color: "#1890ff" }}
                  >
                    <i class="fa-solid fa-phone"></i>
                  </div>
                ) : null}

                <p
                  className={
                    +Number(selectedperson) === Number(index.toString())
                      ? "clickedJop TeamMembers_details"
                      : " TeamMembers_details"
                  }
                >
                  {item.Numbers != null &&
                    item.Numbers.map((phone) => (
                      <div
                        style={{
                          color: "rgb(155 155 155)",
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      >
                        {i18n.language === "en"
                          ? phone.PhoneNumberEn
                          : phone.PhoneNumber}
                      </div>
                    ))}
                </p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(AppContact);
