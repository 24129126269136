import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "antd/dist/antd.css";
import "animate.css";
import { LanguageProvider } from "./services/LanguageContext";
import { HashRouter } from "react-router-dom";
ReactDOM.render(
  <HashRouter>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </HashRouter>,
  document.getElementById("root")
);
