import "./App.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.light.css";
import { Route, Switch, Redirect } from "react-router-dom";
import User from "./views/User";
import Posts from "./views/Posts";
import AppHeader from "./components/common/header";
import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import AppFooter from "./components/common/footer";
import { useEffect, useState } from "react";
import { ALL } from "./services/SharedApi/Section.Api";
import PostsDetails from "./views/PostDetails";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollTop";
import { useTranslation } from "react-i18next";
import AppComponent2 from "./views/User/Components/AppComponent2";
function App() {
  const [data, setdata] = useState({});
  const { t, i18n } = useTranslation();
  if (i18n.language === "en-US") {
    i18n.init();
    document.documentElement.setAttribute("lang", "en");
    i18n.changeLanguage("ar");
  }
  useEffect(async () => {
    console.log(i18n.language);

    document.scrollingElement && (document.scrollingElement.scrollTop = 0);
    await ALL()
      .then((res) => setdata(res))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="App">
      {/* <MetaTags>
        <title>{"الإتحاد الدولي للخدمات المالية والإلكترونية"}</title>
        <meta
          property="og:title"
          content="الإتحاد الدولي للخدمات المالية والإلكترونية"
          data-rh="true"
        />
        <meta
          property="og:description"
          content="A dummies guide to getting up and running with selenium-webdriver"
          data-rh="true"
        ></meta>
        <meta
          property="og:image"
          content="https://api.edf.ly/Uplode/4e314e06-f5ca-4ac5-81a7-8e9024ff843f.jpg"
          data-rh="true"
        />
      </MetaTags> */}
      <Layout className="mainLayout">
        <Header>
          <AppHeader />
        </Header>
        <div style={{ minHeight: "500px" }}>
          <Content>
            <ScrollToTop />
            <Switch>
              <Route path="/home" exact component={User} />
              <Route path="/post/:post" exact component={Posts} />
              <Route path="/postDetails/:id" exact component={PostsDetails} />
              <Route path="/downloadApp" exact component={AppComponent2} />
              <Redirect to="/home" />
            </Switch>
          </Content>
        </div>

        <Footer>
          <AppFooter data={data?.Social?.filter((e) => e.Type !== "Video")} />
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
