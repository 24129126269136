import React from "react";

import { Collapse, Button, Descriptions } from "antd";

const { Panel } = Collapse;

function AppFaq({
  t,
  i18n,
  data,
  Details = "Quidem reiciendis iure, aperiam blanditiis. Alias esse, nam, ea quam",
}) {
  const dataquestion = [
    {
      Question: "Can I change plan or cancel at any time?",
      QuestionEn: "Can I change plan or cancel at any time?",
      Answer:
        "	Ut per tempor latine fuisset, cu quod posidonium vix. Mei cu erant bonorum, te ius vide maiorumhendrerit. In alii instructior vix, vis et elit	maluisset, usu recusabo atomorum ut. Nibh diceret dolores vix et, id omnis adhuc maiestatis vim, ei mel legendos mnesarchum argumentum. Semper nusquam	urbanitas sea te.",
    },
    {
      Question: "How to setup the theme?",
      QuestionEn: "How to setup the theme?",
      Answer:
        "	Ut per tempor latine fuisset, cu quod posidonium vix. Mei cu erant bonorum, te ius vide maiorumhendrerit. In alii instructior vix, vis et elit	maluisset, usu recusabo atomorum ut. Nibh diceret dolores vix et, id omnis adhuc maiestatis vim, ei mel legendos mnesarchum argumentum. Semper nusquam	urbanitas sea te.",

      AnswerEn:
        "	Ut per tempor latine fuisset, cu quod posidonium vix. Mei cu erant bonorum, te ius vide maiorumhendrerit. In alii instructior vix, vis et elit	maluisset, usu recusabo atomorum ut. Nibh diceret dolores vix et, id omnis adhuc maiestatis vim, ei mel legendos mnesarchum argumentum. Semper nusquam	urbanitas sea te.",
    },
  ];
  return (
    <div id="/home#faq" className="block faqBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>{t("Frequently Asked Questions")}</h2>
        </div>
        <Collapse defaultActiveKey={[]}>
          {data?.map((item, index) => (
            <Panel
              header={
                i18n.language === "en" ? item.QuestionTextEn : item.QuestionText
              }
              key={index}
            >
              <p style={{ direction: "ltr" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "en" ? item.AnswerEn : item.Answer,
                  }}
                ></div>
              </p>
            </Panel>
          ))}
        </Collapse>
        <div className="quickSupport"></div>
      </div>
    </div>
  );
}

export default React.memo(AppFaq);
