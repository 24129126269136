import { Card, Col, Row } from "antd";
import React from "react";
import Meta from "antd/lib/card/Meta";
import { ApiBaseUrl } from "../../../services/config";

const Partners = ({ t, i18n, data }) => {
  return (
    <>
      <div className="block aboutBlock ">
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>{t("Partners")}</h2>
          </div>
          <Row
            gutter={[16, 16]}
            justify="center"
            className="animate__animated animate__zoomIn"
          >
            {data?.map((item, index) => {
              return (
                <Col
                  xs={{ span: 8 }}
                  sm={{ span: 8 }}
                  md={{ span: 4 }}
                  key={item.key}
                >
                  <Card
                    hoverable={true}
                    onClick={() => window.open(item?.Link, "_blank").focus()}
                    cover={
                      <img
                        src={ApiBaseUrl + item?.ImagePath}
                        width="100%"
                        height="100%"
                      />
                    }
                  >
                    {item.TitleEn && item.TitleEn.length > 0 ? (
                      <Meta
                        title={
                          i18n.language === "en" ? item.TitleEn : item.Title
                        }
                        style={{ textAlign: "center" }}
                      />
                    ) : null}
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default React.memo(Partners);
