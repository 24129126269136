import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { redirect, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { ApiBaseUrl } from "../../../services/config";

function Service({ t, i18n, details, data }) {
  function Downloadnow(){
    console.log('heee');
    // useNavigate('/downloadApp');
  }
  // const history = useNavigate();
  return (
    <div>
      <>
        <div id="/home#service" className="block featureBlock bgGray">
          <div className="container-fluid">
            <div className="titleHolder">
              <h2>{t("Our Services")}</h2>
            </div>
            <Row justify="center" gutter={[16, 16]}>
              {data?.map((ele) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: data.length < 1 ? 12 : 8 }}
                >
                  <Card
                    hoverable={true}
                    onClick={() => ele.Title === 'UniPay' ? '' : window.open(ele?.Link, "_blank").focus()}

                    cover={
                      <img
                        src={ApiBaseUrl + ele?.ImagePath}
                        width="100%"
                        height="100%"
                      />
                    }
                    style={{
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                  >
                    <Meta
                      title={i18n.language === "en" ? ele.TitleEn : ele.Title}
                      description={
                        i18n.language === "en"
                          ? ele.DescriptionEn
                          : ele.Description }
                    />
                  <div className={ele.Title === 'UniPay' ? 'margintop2': 'margintop2 Hide'} >
                   <NavLink to={"/downloadApp"}>
                    <h6 className="color-blue">
                    {t('Download Now')}                
                    </h6>
                    </NavLink>
                  </div>
                   {/* <div>{ele.Title === 'UniPay' ? : 
                        <></>? <></>} */}
                    {/* </div>  */}
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}

export default Service;
