import React, { useEffect, useState } from "react";
import { POSTS_ID } from "./API";

import { useTranslation } from "react-i18next";
import "./style.css";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ApiBaseUrl } from "../../services/config";
import MetaTags from "react-meta-tags";
const PostDetails = () => {
  const { i18n } = useTranslation();
  const [data, setdata] = useState({});
  let { id } = useParams();

  useEffect(async () => {
    window.scrollTo(0, 0);
    await POSTS_ID(id)
      .then((res) => {
        setdata(res);
      })
      .catch(() => {});
  }, [id]);

  return (
    <>
      <div
        id="works"
        className="block postDetails"
        style={{
          marginTop: "100px",
          backgroundImage: `url(${
            ApiBaseUrl + data?.ImagePath?.replaceAll("\\", "/")
          })`,
          //   background: `url(${}) no-repeat`,
        }}
      >
        <MetaTags>
          <title>{i18n.language === "en" ? data.TitleEn : data.Title}</title>
          <meta property="og:title" content={data.Title} rea />
          <meta
            property="og:description"
            content="Post details secic"
            data-rh="true"
          ></meta>
          <meta
            property="og:image"
            content="https://api.edf.ly/Uplode/4e314e06-f5ca-4ac5-81a7-8e9024ff843f.jpg"
            data-rh="true"
          />
        </MetaTags>
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>{i18n.language === "en" ? data.TitleEn : data.Title}</h2>
            <p>{i18n.language === "en" ? data.SubTitleEn : data.SubTitle}</p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <p
          style={{
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <div
            style={{ padding: "5%", direction: "ltr" }}
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en" ? data.DescriptionEn : data.Description,
            }}
          ></div>
        </p>
      </div>
    </>
  );
};

export default PostDetails;
