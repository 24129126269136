import { Button, Modal } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
const AppWorks = ({
  section,
  i18n,
  Video = "https://www.youtube.com/watch?v=4aVSqeXS6oo",
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div id="/home#works" className="block worksBlock">
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>
              {section?.length > 0
                ? i18n.language === "en"
                  ? section[0]?.ContentEn
                  : section[0]?.Content
                : null}
            </h2>
            <p>
              {section?.length > 0
                ? i18n.language === "en"
                  ? section[1]?.ContentEn
                  : section[1]?.Content
                : null}
            </p>
          </div>
          <div className="contentHolder">
            <Button onClick={showModal}>
              <i className="fas fa-play"></i>
            </Button>
          </div>
        </div>
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          width={"50%"}
        >
          <ReactPlayer width={"100%"} url={Video} />
        </Modal>
      </div>
    </>
  );
};

export default AppWorks;
