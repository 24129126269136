import AppHeader from "../../components/common/header";

import { Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import AppHome from "./Components/home";
import AppFooter from "../../components/common/footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ALL } from "../../services/SharedApi/Section.Api";

const { Header } = Layout;

function User() {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState({});
  useEffect(async () => {
    //  if (!document.documentElement.getAttribute("lang")) {
    await ALL()
      .then((res) => setdata(res))
      .catch((err) => console.log(err));
  }, []);

  return <AppHome data={data} />;
}

export default User;
